import { OrderSubtypeEnum } from '@portal/schema';
import { IWorkFlowStep } from './types';
import { fulfilledTermOrUnconfirmed } from './util';

export const FACILITY_WORKFLOW: IWorkFlowStep[] = [
  { name: 'new' },
  {
    name: 'return',
  },
  {
    name: 'cancel_reason',
    skip: ({ subtype }) => subtype !== OrderSubtypeEnum.Final,
  },
  {
    name: 'address',
  },
  {
    name: 'facility_expectations',
  },
  {
    name: 'contact',
  },
  {
    name: 'date',
  },
  {
    name: 'time',
    skip: ({ intervalConfig, confirmedDate }) =>
      (!confirmedDate && !intervalConfig) || (!!intervalConfig && intervalConfig.forced),
  },
  {
    name: 'waitlist',
    skip: ({ scheduledDate, waitlistedDates }) => !scheduledDate || waitlistedDates.length === 0,
  },
  {
    name: 'term_commitment',
    skip: fulfilledTermOrUnconfirmed,
  },
  {
    name: 'term_commitment_balance',
    skip: fulfilledTermOrUnconfirmed,
  },
  { name: 'confirm' },
];
