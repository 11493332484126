import { OrderServiceTypeEnum, OrderSubtypeEnum, OrderTypeEnum } from '@portal/schema';
import { IWorkFlowStep } from './types';
import { fulfilledTermOrUnconfirmed } from './util';

export const STANDARD_WORKFLOW: IWorkFlowStep[] = [
  { name: 'new' },
  {
    name: 'return',
    skip: ({ type }) => type === OrderTypeEnum.Pickup,
  },
  {
    name: 'pickup',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'cancel_reason',
    skip: ({ subtype }) => subtype !== OrderSubtypeEnum.Final,
  },
  {
    name: 'address',
    skip: ({ serviceType }) => serviceType === OrderServiceTypeEnum.Disposal,
  },
  {
    name: 'address_details',
    skip: ({ serviceType }) =>
      serviceType === OrderServiceTypeEnum.Facility || serviceType === OrderServiceTypeEnum.Disposal,
  },
  {
    name: 'facility_expectations',
    skip: ({ serviceType }) => serviceType !== OrderServiceTypeEnum.Facility,
  },
  {
    name: 'disposal_expectations',
    skip: ({ serviceType }) => serviceType !== OrderServiceTypeEnum.Disposal,
  },
  {
    name: 'date',
    skip: ({ serviceType }) => serviceType === OrderServiceTypeEnum.Disposal,
  },
  {
    name: 'time',
    skip: ({ intervalConfig, serviceType, confirmedDate }) =>
      serviceType === OrderServiceTypeEnum.Disposal ||
      (!confirmedDate && !intervalConfig) ||
      (!!intervalConfig && (intervalConfig.forced || serviceType === OrderServiceTypeEnum.Facility)),
  },
  {
    name: 'waitlist',
    skip: ({ scheduledDate, waitlistedDates }) => !scheduledDate || waitlistedDates.length === 0,
  },
  {
    name: 'term_commitment',
    skip: fulfilledTermOrUnconfirmed,
  },
  {
    name: 'term_commitment_balance',
    skip: fulfilledTermOrUnconfirmed,
  },
  {
    name: 'contact',
    skip: ({ serviceType }) => serviceType === OrderServiceTypeEnum.Disposal,
  },
  { name: 'confirm' },
];
