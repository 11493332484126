import { OrderSubtypeEnum, OrderTypeEnum } from '@portal/schema';
import { IWorkFlowStep } from './types';
import { fulfilledTermOrUnconfirmed } from './util';

export const SHIPPING_WORKFLOW: IWorkFlowStep[] = [
  { name: 'new' },
  {
    name: 'return',
    skip: ({ type }) => type === OrderTypeEnum.Pickup,
  },
  {
    name: 'cancel_reason',
    skip: ({ subtype }) => subtype !== OrderSubtypeEnum.Final,
  },
  {
    name: 'address',
  },
  {
    name: 'inbound_shipping_prepare',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'inbound_shipment_count',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'inbound_shipment_items',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'outbound_item_info',
    skip: ({ type }) => type === OrderTypeEnum.Pickup,
  },
  {
    name: 'inbound_shipping_options',
    skip: ({ type }) => type === OrderTypeEnum.Return,
  },
  {
    name: 'outbound_shipping_options',
    skip: ({ type }) => type === OrderTypeEnum.Pickup,
  },
  {
    name: 'term_commitment',
    skip: fulfilledTermOrUnconfirmed,
  },
  {
    name: 'term_commitment_balance',
    skip: fulfilledTermOrUnconfirmed,
  },
  {
    name: 'contact',
  },
  { name: 'confirm' },
];
