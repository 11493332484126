import {
  Account__CancelIntent,
  Account__Marketing__CouponFragment,
  DropOff__Appointment,
  Item,
  ItemPricingFragment,
  LaborCostDetailsFragment,
  Maybe,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  Partnerships__Partner,
  AvailabilityWaitlistRequestFragment,
  Order__Contact,
  Order__PickupVehicleKind,
} from '@portal/schema';
import { ILaborRate } from '@admin/types';
import { IPickupInventoryInput } from '@shared/types/pickup_inventory_input';

import { IAddressWithDetails } from './address';

interface FacilityWarehouse {
  id: string;
  accessHours: string[];
  accessInstructions: string;
}

export enum OrderStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED',
  Pending = 'PENDING',
}

export enum OrderState {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Enroute = 'ENROUTE',
  Graded = 'GRADED',
  Packed = 'PACKED',
  Palletized = 'PALLETIZED',
  Pending = 'PENDING',
  Prepared = 'PREPARED',
  Returned = 'RETURNED',
}

export interface IOrderService extends Required<IPickupInventoryInput> {
  id: string;
  type: OrderTypeEnum;
  subtype: OrderSubtypeEnum;
  cuft: number | null;
  estimatedCuft: number;
  estimatedCuftPerHourPerMover: number | null;
  cuftPerHourPerMover: number;
  items: Array<Item & { __typename: 'Item' }>;
  started: string;
}

export interface IOrderPermissions {
  active: boolean;
  locked: boolean;
  cancelable: boolean;
  relocatable: boolean;
  reschedulable: boolean;
  changeablePickupInventory: boolean;
  changeableReturnInventory: boolean;
  chargeForCancellation: boolean;
  chargeLateFee: boolean;
}

export interface IOrder {
  id: string;
  number: string;
  scheduled: string; // ISO datetime
  window: string; // ISO duration
  permissions: IOrderPermissions;
  type: OrderTypeEnum;
  subtype: OrderSubtypeEnum;
  state: OrderState;
  status: OrderStatus;
  services: IOrderService[];
  address: IAddressWithDetails;
  contact?: Order__Contact;
  laborRate: ILaborRate;
  accountID: string;
  perItemPricingInfo: ItemPricingFragment;
  serviceType?: OrderServiceTypeEnum;
  started: boolean;
  dropOffAppointment: Maybe<DropOff__Appointment>;
  accountCancelIntent: Maybe<Account__CancelIntent>;
  shipped: boolean;
  bookingPartner?: Maybe<Partnerships__Partner>;
  facilityWarehouse?: Maybe<FacilityWarehouse>;
  laborCostDetails?: Maybe<LaborCostDetailsFragment>;
  coupon?: Account__Marketing__CouponFragment;
  waitlistRequests?: AvailabilityWaitlistRequestFragment[];
  pickupVehicleKind?: Order__PickupVehicleKind;
  documentHubURL?: string;
  formattedArrivalWindow: string;
  customerPinCode: string;
}
